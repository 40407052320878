import * as React from 'react'
import GatsbyImage from 'gatsby-image'
import css from '@emotion/css'
import { Link as GatsbyLink } from 'gatsby'
import { colors } from '$theme/legacy'
import { rhythm } from '$utils/typography'
import { PostType, Post } from '$utils/globals'
import { getPathByPost } from '$utils/postTypes'
import Text from '$components/Text'

type Props = {
  post: Post
}

function PostCard(props: Props) {
  const { post: p } = props
  if (!p) {
    return null
  }

  return (
    <GatsbyLink
      to={getPathByPost(p)}
      css={css`
        display: block;
        max-width: 100%;
        text-decoration: none;
        &:focus {
          outline: 0;
          box-shadow: 0 0 0 2px ${colors.greens.seven};
        }
      `}
    >
      <GatsbyImage
        // @ts-ignore
        fluid={p.image.childImageSharp.fluid}
        css={{
          borderRadius: '7px',
          width: '100%',
          marginBottom: '10px',
        }}
      />
      {p.type === PostType.Destination && (
        <div
          css={{
            marginBottom: rhythm(0.25),

            textTransform: 'capitalize',
          }}
        >
          <Text size={Text.Sizes.Normal} color={Text.Colors.Primary}>
            {p.city}, {p.country}
          </Text>
        </div>
      )}
      <h5 css={{ fontSize: '18px', color: colors.grays.zero }}>{p.title}</h5>
    </GatsbyLink>
  )
}
export default PostCard
