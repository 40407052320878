import { rhythm } from '$utils/typography'
import { colors, fonts } from '$theme/legacy'
import { css } from '@emotion/core'

export default css`
  & .responsive-iframe {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  & .responsive-iframe iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: 0;
  }
`
