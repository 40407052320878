import { Post, PostType } from './globals'

const types = {
  destination: 'destinations',
  guide: 'guides',
}

export function getRouteByType(type: string) {
  const pType = getPostTypeByFrontmatterType(type)
  return pType ? types[pType] : null
}

export function getPostTypeByFrontmatterType(type: string) {
  switch (type) {
    case PostType.Destination:
      return PostType.Destination
    case PostType.Guide:
      return PostType.Guide
    default:
      return null
  }
}

export function getPathByPost(post: Post) {
  const { language, type, slug } = post
  if (!type) {
    throw new Error('Post must have type')
  }
  const route = getRouteByType(type)

  if (language && route && slug) {
    return `/${route}/${language}/${slug}`
  } else if (route && slug) {
    return `/${route}/${slug}`
  } else {
    throw new Error('Missing post details to build path')
  }
}
