/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'
import { colors } from '$theme/legacy'
import * as Colors from '$theme/colors'

export enum Type {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

type Props = {
  children?: React.ReactNode
  componentType?: Type
} & React.HTMLProps<HTMLButtonElement>

const baseCss = css`
  appearance: none;
  border: none;
  min-width: 116px;
  height: 40px;
  border-radius: 20px;
  font-size: 0.89rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0 32px;
  line-height: 1;
  user-select: none;
  &:selected {
    outline: 0;
    box-shadow: 0 0 0 2px ${colors.greens.seven};
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${colors.greens.seven};
  }
`

const primaryCss = css`
  background: ${Colors.Greens.Zero};
  color: white;
`

const secondaryCss = css`
  border: 2px solid ${colors.greens.zero};
  color: ${colors.greens.zero};
  background: transparent;
`

const tertiaryCss = css`
  background: white;
  color: ${colors.greens.zero};
`

const resolveType = {
  [Type.Primary]: primaryCss,
  [Type.Secondary]: secondaryCss,
  [Type.Tertiary]: tertiaryCss,
}

function Button(props: Props) {
  const { children, componentType = Type.Primary } = props
  return <button css={[baseCss, resolveType[componentType]]}>{children}</button>
}

Button.Types = Type

export default Button
