/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'
type Props = {
  disabled?: boolean
  children?: React.ReactNode
}
function VisuallHidden(props: Props) {
  if (props.disabled) {
    return <React.Fragment>{props.children}</React.Fragment>
  }
  return (
    <div
      css={css`
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      `}
    >
      {props.children}
    </div>
  )
}

export default VisuallHidden
