export function replaceSpacesWithDashes(str) {
  return str.replace(/\s/g, '-').toLowerCase()
}

export function groupBy<T>(arr: Array<T>, criteria: string) {
  return arr.reduce<{ [key: string]: Array<T> }>(function(obj, item) {
    var key = item[criteria]

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = []
    }

    // Push the value to the object
    obj[key].push(item)

    // Return the object to the next item in the loop
    return obj
  }, {})
}
