/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'

import { colors } from '$theme/legacy'
import { checkPropTypes, element } from 'prop-types'

export enum Type {
  Primary = 'primary',
}

export enum Element {
  Input = 'input',
  Textarea = 'textarea',
}

type Props = {
  children?: React.ReactNode
  componentType?: Type
} & React.HTMLProps<HTMLInputElement>

const baseCss = css`
  border: 1px solid ${colors.grays.five};
  border-radius: 20px;
  font-size: 0.89rem;
  font-weight: 500;
  width: 100%;

  &::placeholder {
    line-height: 1;
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${colors.greens.seven};
  }
`
const baseInputCss = css`
  padding: 0 27px;
  line-height: 1;
  height: 40px;
`

const primaryCss = css``

const resolveType = {
  [Type.Primary]: primaryCss,
}

function Input(props: Props) {
  const { children, componentType = Type.Primary, ...rest } = props

  return (
    <input css={[baseCss, baseInputCss, resolveType[componentType]]} {...rest}>
      {children}
    </input>
  )
}

// export function Textarea(props: Props) {
// 	const { children, componentType = Type.Primary, ...rest } = props;
// 	return (
// 		<textarea
// 			css={[baseCss, baseTextareaCss, resolveType[componentType]]}
// 			{...rest}
// 		>
// 			{children}
// 		</textarea>
// 	);
// }

export function SplitInputContainer(props) {
  return (
    <div
      className={props.className}
      css={css`
        display: flex;
        & > input {
          flex: 1;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-right: none;
        }
        & > button {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          padding: 0px 20px 0 15px;
          min-width: unset;
        }
      `}
    >
      {props.children}
    </div>
  )
}

// Input.Textarea = Textarea;
Input.Types = Type
Input.SplitContainer = SplitInputContainer

export default Input
