import { Query } from 'graphqlTypes'
import { Post, PostType } from './globals'

type Params = {
  markdownData: Query['allMarkdownRemark']
  currentPost: Post
}

export function extractPostsFromAllMarkdownRemarkQueryByType(params: {
  markdownData: Query['allMarkdownRemark']
  type: PostType
}) {
  const { markdownData, type } = params
  return (markdownData && markdownData.edges ? markdownData.edges : [])
    .filter(edge => {
      if (edge && edge.node && edge.node.frontmatter) {
        const {
          node: { frontmatter: p },
        } = edge
        return p.type === type && p.slug
      }
    })
    .map(e => (e && e.node && e.node.frontmatter ? e.node.frontmatter : null))
    .filter(Boolean)
}

type PostWithScore = Post & {
  _score: number
}

function buildRelatedPosts({ markdownData, currentPost }: Params) {
  if (currentPost.type === 'guide') {
    const preGuides = extractPostsFromAllMarkdownRemarkQueryByType({
      markdownData,
      type: PostType.Guide,
    }).filter(p => p && p.slug !== currentPost.slug)

    const guides = [
      preGuides.find(p => !!p && p.slug === 'our-10-favorite-destinations'),
      preGuides.find(
        p => !!p && p.slug === 'five-getaways-that-wont-break-the-bank',
      ),
      preGuides.find(p => !!p && p.slug === 'budgeting-for-long-term-travel'),
      preGuides.find(p => !!p && p.slug === 'using-airbnb-like-a-pro'),
    ].filter(Boolean)

    return guides
  }

  const posts = extractPostsFromAllMarkdownRemarkQueryByType({
    markdownData,
    type: PostType.Destination,
  }).filter(p => p && p.slug !== currentPost.slug)

  const relatedPosts: Array<PostWithScore> = []
  posts.forEach(p => {
    if (!p) return
    if (
      p.city === currentPost.city &&
      p.country === currentPost.country &&
      p.continent === currentPost.continent
    ) {
      relatedPosts.push({
        ...p,
        _score: 60,
      })
    } else if (p.country === currentPost.country) {
      relatedPosts.push({
        ...p,
        _score: 30,
      })
    } else if (p.continent === currentPost.continent) {
      relatedPosts.push({
        ...p,
        _score: 20,
      })
    }
  })
  relatedPosts.sort((a, b) => b._score - a._score)
  // relatedPosts.sort((a, b) => {
  // 	return a._score > b._score ? 1 : a._score === b._score ? 0 : -1;
  // });
  return relatedPosts.slice(0, 25)
}
export default buildRelatedPosts
