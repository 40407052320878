import Blockquote from '$components/Blockquote'
import Breadcrumbs from '$components/Breadcrumbs'
import Layout from '$components/Layout'
import DSLink from '$components/Link'
import NewsletterLarge from '$components/NewsletterLarge'
import NewsletterSmall from '$components/NewsletterSmall'
import Paragraph from '$components/Paragraph'
import PostCard from '$components/PostCard'
import TableOfContents from '$components/TableOfContents'
import { mediaQueries as mq } from '$theme/legacy'
import buildRelatedPosts from '$utils/buildRelatedPosts'
import { getRouteByType } from '$utils/postTypes'
import { rhythm } from '$utils/typography'
import css from '@emotion/css'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Query } from 'graphqlTypes'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { oc } from 'ts-optchain'
import Author from './UI/Author'
import responsiveIframe from './UI/responsive-iframe'

const { useMemo } = React

const baseURL = `https://www.scope.travel` // TODO retrieve from settings

type Props = {
  data: Query
}

function Template(props: Props) {
  const {
    data: { markdownRemark, allMarkdownRemark },
  } = props

  const post = oc(markdownRemark).frontmatter()
  const html = oc(markdownRemark).html()
  const tableOfContents = oc(markdownRemark).tableOfContents()
  const timeToRead = oc(markdownRemark).timeToRead()

  const relatedPosts = useMemo(
    () => {
      return post
        ? buildRelatedPosts({
            markdownData: allMarkdownRemark,
            currentPost: post,
          })
        : []
    },
    [post, allMarkdownRemark],
  )

  if (!markdownRemark) {
    return null
  }

  if (!post) {
    return null
  }

  const ocPost = oc(post)
  const title = ocPost.title('')
  const description = ocPost.description('')
  const type = ocPost.type('')
  const slug = ocPost.slug('')
  const ogUrl = type && slug ? `${baseURL}/${getRouteByType(type)}/${slug}` : ''
  const ogImage =
    type && slug ? `${baseURL}/${getRouteByType(type)}/${slug}` : ''
  const author = ocPost.author('')
  const publishedAt = ocPost.publishedAt('')

  return (
    <Layout>
      <Helmet>
        <meta name="Description" content={description} />
        <meta property="og:url" content={ogUrl} />

        <meta property="og:type" content="article" />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <title>{title}</title>
      </Helmet>
      <Content>
        <PostContainer>
          <MainContent>
            <ArticleContainer>
              <Breadcrumbs post={post} />
              {title && (
                <h1
                  css={css`
                    margin-bottom: ${rhythm(0.5)};
                  `}
                >
                  {title}
                </h1>
              )}
              <Author
                author={author}
                timeToRead={timeToRead}
                date={publishedAt}
              />
              {/* <TranslatedPosts
                posts={convertGraphQLPostsResultToPosts(translatedPosts)}
              /> */}

              <Img
                //@ts-ignore error fluid type confict
                fluid={post.image.childImageSharp.fluid}
                css={css`
                  margin-bottom: ${rhythm(1)};
                `}
              />

              <Paragraph type={Paragraph.Types.Lead}>{description}</Paragraph>
              <TableOfContents html={tableOfContents} />
              {html && (
                <PostStyles dangerouslySetInnerHTML={{ __html: html }} />
              )}
              <NewsletterLarge />
            </ArticleContainer>
            <SideBarContainer>
              <NewsletterSmall />
              <h3>Related Articles</h3>
              {relatedPosts.map(
                p => p && <PostCard post={p} key={p.slug || ''} />,
              )}
            </SideBarContainer>
          </MainContent>
        </PostContainer>
      </Content>
    </Layout>
  )
}

export default Template

export const pageQuery = graphql`
  query($slug: String!, $language: String) {
    markdownRemark(
      frontmatter: { language: { eq: $language }, slug: { eq: $slug } }
    ) {
      html
      timeToRead
      tableOfContents(pathToSlugField: "frontmatter.path")
      frontmatter {
        publishedAt(formatString: "MMMM DD, YYYY")
        path
        title
        description
        type
        slug
        author
        city
        country
        continent
        image {
          childImageSharp {
            fluid(maxWidth: 650, cropFocus: CENTER, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___publishedAt] }
      limit: 1000
      filter: {
        frontmatter: {
          visibility: { ne: "hidden" }
          type: { in: ["destination", "guide"] }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            type
            title
            country
            continent
            city
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 400, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    translatedPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___publishedAt] }
      limit: 1000
      filter: {
        frontmatter: {
          slug: { eq: $slug }
          visibility: { ne: "hidden" }
          type: { in: ["destination", "guide"] }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            type
            title
            country
            continent
            city
            slug
            language
          }
        }
      }
    }
  }
`

const Content = styled.div({
  padding: ` 0 `,
})

const PostContainer = styled.div(
  {
    maxWidth: 960,
    margin: `${rhythm(1)} auto 0 auto`,
    padding: '0 20px',
    overflow: 'hidden',
    [mq.MIN_DEFAULT_MEDIA_QUERY]: {
      margin: `${rhythm(2)} auto 0 auto`,
    },
  },
  responsiveIframe,
)

const PostStyles = styled.div([
  css`
    & blockquote {
      ${Blockquote.CSS.normal}
    }
    & p {
      ${Paragraph.CSS.normal}
    }
    & a {
      ${DSLink.CSS.normal}
    }
  `,
])

const MainContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 600,
  margin: '0 auto',
  [mq.MIN_DEFAULT_MEDIA_QUERY]: {
    maxWidth: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

const SideBarContainer = styled.div({
  width: '100%',
  [mq.MIN_DEFAULT_MEDIA_QUERY]: {
    width: 'calc((3 / 12) * 100%)',
    // paddingRight: '20px',
  },
})
const ArticleContainer = styled.article({
  width: '100%',
  [mq.MIN_DEFAULT_MEDIA_QUERY]: {
    width: 'calc((8 / 12) * 100%)',
  },
})

// function TranslatedPosts({ posts }: { posts: Array<Post> }) {
//   // If no translations do not render block
//   // language prop will be null or non-existent
//   if (!posts.some(p => !!p.language)) {
//     return null
//   }

//   return (
//     posts.length > 0 && (
//       <div>
//         <Text size={Text.Sizes.Small}>
//           This page has been translated into:{' '}
//         </Text>
//         {posts.map((p, i) => {
//           let comp

//           if (!p.language) {
//             comp = (
//               <GatsbyLink to={getPathByPost(p)} key={p.slug}>
//                 <Text
//                   size={Text.Sizes.Small}
//                   specialType={Text.SpecialTypes.Link}
//                 >
//                   English
//                 </Text>
//               </GatsbyLink>
//             )
//           } else {
//             comp = (
//               <GatsbyLink to={getPathByPost(p)} key={p.slug}>
//                 <Text
//                   size={Text.Sizes.Small}
//                   specialType={Text.SpecialTypes.Link}
//                 >
//                   {languages[p.language]}
//                 </Text>
//               </GatsbyLink>
//             )
//           }

//           return [i > 0 && ', ', comp]
//         })}
//       </div>
//     )
//   )
// }
