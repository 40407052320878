import * as React from 'react'
import Text from '$components/Text'
import { Maybe } from '$utils/globals'

type Props = {
  author: string
  date: string
  timeToRead: Maybe<number>
}

export default function Author({ author, date, timeToRead }: Props) {
  return (
    <p>
      <Text size={Text.Sizes.Small} color={Text.Colors.PrimaryLight}>
        By {author} on {date}. Read time:{' '}
        {timeToRead != null && (
          <React.Fragment>
            {timeToRead} {Math.round(timeToRead) === 1 ? 'min' : 'mins'}
          </React.Fragment>
        )}
        .
      </Text>
    </p>
  )
}
