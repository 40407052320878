import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { PostType, Post } from '$utils/globals'
import { replaceSpacesWithDashes } from '$utils/helpers'
import css from '@emotion/css'
import Text from '$components/Text'
import { oc } from 'ts-optchain'
import { rhythm } from '$utils/typography'

type Props = {
  post: Post
}
function Breadcrumbs(props: Props) {
  const { post } = props

  if (post.type === PostType.Video) {
    return null
  }
  if (post.type === PostType.Guide) {
    return (
      <div
        css={css`
          margin-bottom: ${rhythm(1)};
        `}
      >
        <GatsbyLink to="/">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
            Home
          </Text>
        </GatsbyLink>
        <Separator />
        <GatsbyLink to="/guides">
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
            Guides
          </Text>
        </GatsbyLink>
      </div>
    )
  }
  return (
    <div
      css={css`
        margin-bottom: ${rhythm(1)};
      `}
    >
      <GatsbyLink to="/">
        <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
          Home
        </Text>
      </GatsbyLink>
      <Separator />
      <GatsbyLink to="/destinations">
        <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
          Destinations
        </Text>
      </GatsbyLink>
      <Separator />
      <GatsbyLink
        to={`/destinations/${replaceSpacesWithDashes(post.continent)}`}
      >
        <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
          <span
            css={css`
              text-transform: capitalize;
            `}
          >
            {oc(post)
              .continent('')
              .trim()}
          </span>
        </Text>
      </GatsbyLink>
    </div>
  )
}

export const Separator = () => {
  return (
    <React.Fragment>
      {' '}
      <Text size={Text.Sizes.Small} color={Text.Colors.Primary}>
        /
      </Text>{' '}
    </React.Fragment>
  )
}

export default Breadcrumbs
