import Button from '$components/Button'
import Input from '$components/Input'
import Text from '$components/Text'
import VisuallyHidden from '$components/VisuallyHidden'
import Colors from '$theme/colors'
import { mediaQueries as mq } from '$theme/legacy'
import { rhythm } from '$utils/typography'
import css from '@emotion/css'
import * as React from 'react'

function NewsletterSmall() {
  return (
    <div
      css={css`
        display: none;
        background-color: ${Colors.Grays.Seven};
        padding: ${rhythm(0.5)};
        border-radius: 7px;
        ${mq.MIN_DEFAULT_MEDIA_QUERY} {
          display: block;
          margin-bottom: ${rhythm(1)};
        }
      `}
    >
      <form
        name="newsletter"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        method="post"
        css={css`
          margin: 0;
        `}
      >
        <input type="hidden" name="form-name" value="newsletter" />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            & > input,
            button {
              width: 100%;
            }
          `}
        >
          <Text
            weight={Text.Weights.Bolder}
            color={Text.Colors.PrimaryColored}
            size={Text.Sizes.ExtraSmall}
            spacing={Text.Spacings.Small}
          >
            {' '}
            NEWSLETTER
          </Text>
          <div
            css={css`
              margin-bottom: ${rhythm(0.5)};
            `}
          >
            <Text weight={Text.Weights.Bold}> Join us for weekly tips</Text>
          </div>

          <VisuallyHidden>
            <label htmlFor="NewsletterSmall-emailInput">Email:</label>
          </VisuallyHidden>
          <Input
            id="NewsletterSmall-emailInput"
            name="email"
            type="email"
            placeholder="Email address"
            css={css`
              margin-bottom: 4px;
            `}
          />
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </div>
  )
}
export default NewsletterSmall
