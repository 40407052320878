import * as React from 'react'
import css from '@emotion/css'

export enum Type {
  Normal = 'normal',
  Lead = 'lead',
}

type Props = {
  type: Type
  children?: React.ReactNode
}

export const normalCss = css`
  font-size: 1rem;
`
export const leadCss = css`
  font-size: 1.25rem;
  font-weight: 500;
`

const resolveType = {
  [Type.Normal]: normalCss,
  [Type.Lead]: leadCss,
}

function Paragraph(props: Props) {
  return <p css={resolveType[props.type]}>{props.children}</p>
}
Paragraph.defaultProps = {
  type: Type.Normal,
}
Paragraph.Types = Type
Paragraph.CSS = {
  normal: normalCss,
  lead: leadCss,
}

export default Paragraph
