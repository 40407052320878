import Button from '$components/Button'
import Input from '$components/Input'
import Paragraph from '$components/Paragraph'
import VisuallyHidden from '$components/VisuallyHidden'
import { rhythm } from '$utils/typography'
import css from '@emotion/css'
import * as React from 'react'

function NewsletterLarge() {
  return (
    <div
      css={css`
        margin-top: ${rhythm(2)};
      `}
    >
      <h1>Join our weekly adventures</h1>

      <Paragraph>
        Gain access to our exclusive travel tips and more!
        <br />
        Expect exotic destinations, big adventures, flavorful food and
        unforgettable stories from our travels.
      </Paragraph>
      <form
        name="newsletter"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        method="post"
      >
        <input type="hidden" name="form-name" value="newsletter" />

        <Input.SplitContainer
          css={css`
            max-width: 500px;
          `}
        >
          <VisuallyHidden>
            <label htmlFor="emailInput">Email:</label>
          </VisuallyHidden>
          <Input
            id="emailInput"
            name="email"
            type="email"
            placeholder="Email address"
            css={css`
              margin-bottom: 0.55rem;
            `}
          />
          <Button type="submit">Submit</Button>
        </Input.SplitContainer>
      </form>
    </div>
  )
}
export default NewsletterLarge
