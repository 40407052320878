import Link from '$components/Link'
import Text from '$components/Text'
import { rhythm } from '$utils/typography'
import css from '@emotion/css'
import { MarkdownRemark } from 'graphqlTypes'
import * as React from 'react'

type Props = {
  html: MarkdownRemark['html']
}

function TableOfContents(props: Props) {
  const { html } = props
  if (!html) {
    return null
  }
  return (
    <React.Fragment>
      <div
        css={css`
          margin-bottom: ${rhythm(1)};
        `}
      >
        <Text size={Text.Sizes.Normal} weight={Text.Weights.Bolder}>
          In this article:
        </Text>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        css={css`
          & > ul {
          }
          & a {
            ${Link.CSS.normal}
          }
        `}
      />
    </React.Fragment>
  )
}
export default TableOfContents
