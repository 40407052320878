import * as React from 'react'
import css from '@emotion/css'
import { colors } from '$theme/legacy'

export enum Type {
  Normal = 'normal',
}

type Props = {
  type: Type
  href?: string
  to?: string
  children?: React.ReactNode
}

export const normalCss = css`
  color: ${colors.greens.zero};
  font-weight: 500;
  text-decoration: none;
`

const resolveType = {
  [Type.Normal]: normalCss,
}

function Link(props: Props) {
  return (
    <a css={resolveType[props.type]} href={props.href}>
      {props.children}
    </a>
  )
}
Link.defaultProps = {
  type: Type.Normal,
}
Link.Types = Type
Link.CSS = {
  normal: normalCss,
}

export default Link
