import { MarkdownRemarkFrontmatter } from 'graphqlTypes'

export type Maybe<T> = T | null | undefined
// Enum have to be export and imported when used
export enum PostType {
  Destination = 'destination',
  Guide = 'guide',
  Video = 'video',
}

export type Post = MarkdownRemarkFrontmatter

// type PostGatsbyImage = {
//   childImageSharp: {
//     fixed: string
//     fluid: string
//   }
// }

// declare global {
//   type Post = {
//     title: string
//     city: string
//     country: string
//     continent: string
//     slug: string
//     path: string
//     type: PostType
//     tags: Array<string>
//     publishedAt: string
//     featured: boolean
//     language?: string
//     image?: PostGatsbyImage
//   }
// }
