import * as React from 'react'
import css from '@emotion/css'

import { rhythm } from '$utils/typography'

type Props = {
  children?: React.ReactNode
}

export const baseCss = css`
  & p {
    font-size: 1.25rem;
    font-weight: 500;
    font-style: italic;
    margin: ${`${rhythm(2)} calc((1 / 12) * 100%)`};
  }
`

function Blockquote(props: Props) {
  const { children } = props
  return <blockquote css={baseCss}>{children}</blockquote>
}

Blockquote.CSS = {
  normal: baseCss,
}

export default Blockquote
